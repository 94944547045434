





























import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import BarChart from "@/components/chart/BarChart.vue";
import i18n from "@/i18n";
import { Log } from "@/types/";

export default Vue.extend({
  components: {
    PageHeading,
    BarChart,
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
      },
    },
    optionsInput: [
      { value: "login", text: i18n.t("auth.login") },
      { value: "logout", text: i18n.t("auth.logout") },
      { value: "forgot", text: i18n.t("auth.forgot-password") },
      { value: "reset", text: i18n.t("auth.reset-password-input") },
    ],
  }),
  mounted() {
    this.$store.dispatch("logs/fetchDailyLogs");
  },
  watch: {
    filter: {
      handler: function () {
        this.$store.commit("logs/setFetchedLog", false);
        this.$store.dispatch("logs/fetchDailyLogs");
        this.logDataChart;
      },
      immediate: true,
    },
  },
  computed: {
    filter: {
      get(): string {
        return this.$store.getters["logs/getFilter"];
      },
      set(value: string) {
        this.$store.commit("logs/setFilter", value);
      },
    },
    dailyLogs: {
      get(): Log[] {
        return this.$store.getters["logs/getChartLogs"];
      },
      set(value: Log[]) {
        this.$store.commit("logs/setChartLog", value);
      },
    },
    logDataChart(): any {
      if (this.dailyLogs) {
        const valuesLog = [] as any[];
        const labelsLog = [] as any[];

        const arrayLog = Object.entries(this.dailyLogs).map((arr) => ({
          labels: arr[0],
          values: arr[1],
        }));

        arrayLog.forEach((v: any) => {
          valuesLog.push(v.values);

          if (!labelsLog.includes(v.labels)) {
            labelsLog.push(v.labels);
          }
        });

        return {
          datasets: [
            {
              backgroundColor: "#58c259",
              data: valuesLog,
            },
          ],
          labels: labelsLog,
        };
      } else {
        return null;
      }
    },
  },
});
