





































































import Vue from "vue";
import { TableField } from "@/types";
import i18n from "@/i18n";
import { User } from "@/types/";

export default Vue.extend({
  name: "UserTable",
  data: () => ({
    currentPage: 1,
    options: [
      {
        value: 1,
        text: "Admin",
      },
      {
        value: 2,
        text: "Broker",
      },
      {
        value: 3,
        text: "User",
      },
    ],
  }),
  props: {
    perPage: { type: Number, required: true },
    page: { type: Number, required: true },
    item: { type: Object, required: true },
  },
  computed: {
    totalRows(): number {
      return Number(this.item.total);
    },
    fields(): TableField[] {
      // Accessing this.$i18n.locale in this computed prop tells Vue that
      // this computed prop should update whenever this.$i18n.locale
      // changes it value, even though we don't use the value.
      const lang = this.$i18n.locale;
      // We add the following just so that minification optimizers
      // do not remove the above statement because the return value isn't used.
      if (!lang) {
        return [];
      }
      return [
        {
          key: "id",
          label: i18n.t("AdminPages.usersTable.id"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "email",
          label: i18n.t("AdminPages.usersTable.email"),
          sortable: true,
          thClass: "b-table-sort-icon-left d-none d-md-table-cell",
          tdClass: "d-none d-md-table-cell",
        },
        {
          key: "active",
          label: i18n.t("AdminPages.usersTable.active"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  watch: {
    currentPage(page) {
      this.$emit("update:page", page);
    },
    page(page) {
      this.currentPage = page;
    },
  },
  methods: {
    proceedToStatus(data: User, value: boolean) {
      this.$emit("editActiveStatus", data, value);
    },
    proceedToRole(data: User, value: number) {
      this.$emit("editRole", data, value);
    },
    proceedToInvite(id: number) {
      this.$emit("sendInvite", id);
    },
    switchUser(id: number) {
      this.$store.dispatch("auth/switchToUser", id);
      this.$router.push({ name: "Loans" });
    },
    toReportPage(id: string) {
      this.$router.push({ name: "Admin Report", params: { id: id } });
    },
  },
});
