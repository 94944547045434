



































































































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import UserTable from "@/components/adminComponents/UserTable.vue";
import { apiAxios } from "@/store/apiAxios";
import ModalContainer from "@/components/ModalContainer.vue";
import AuthService from "@/services/AuthService";
import { User } from "@/types";

let timeout = null as unknown as number;

export default Vue.extend({
  components: {
    PageHeading,
    UserTable,
    ModalContainer,
  },
  data: () => ({
    keyword: "",
    idInvite: null as unknown as number,
    userToEdit: null as unknown,
    valueStatusToEdit: null as unknown as boolean,
    valueRoleToEdit: null as unknown as number,
  }),
  mounted() {
    this.$store.dispatch("user/fetchAllUsers");
  },
  computed: {
    fetched: {
      get(): boolean {
        return this.$store.getters["user/getFetched"];
      },
      set(value: boolean) {
        this.$store.commit("user/setFetched", value);
      },
    },
    broker: {
      get(): User[] {
        return this.$store.getters["user/getUsers"];
      },
      set(value: User[]) {
        this.$store.commit("user/setUsers", value);
      },
    },
    showFeature(): boolean {
      if (process.env.VUE_APP_SHOW_FEATURE !== "true") {
        return false;
      }

      return true;
    },
  },
  methods: {
    searchUserByFilter() {
      clearTimeout(timeout);
      // Make a new timeout set to go off in 800ms
      timeout = setTimeout(() => {
        apiAxios
          .get(`/api/admin/search-users?keyword=${this.keyword}`)
          .then((r) => {
            this.fetched = false;
            this.broker = r.data;
          });
      }, 700);
    },
    initActiveStatus(data: User, value: boolean) {
      this.$bvModal.show("modal-confirm-status");
      this.userToEdit = data;
      this.valueStatusToEdit = value;
    },
    initRole(data: User, value: number) {
      this.$bvModal.show("modal-confirm-role");
      this.userToEdit = data;
      this.valueRoleToEdit = value;
    },
    initInvite(id: number) {
      this.$bvModal.show("modal-confirm-email");
      this.idInvite = id;
    },
    editActiveStatus(data: User, value: boolean) {
      data.active = value;
      AuthService.updateUser(data).then(() => {
        this.$bvModal.hide("modal-confirm-status");
      });
    },
    editRole(data: User, value: number) {
      data.role_id = value;
      AuthService.updateUser(data).then(() => {
        this.$bvModal.hide("modal-confirm-role");
      });
    },
    sendInvite(id: number) {
      apiAxios
        .post("/api/admin/invite", {
          id: id,
        })
        .then(() => {
          this.$bvModal.hide("modal-confirm-email");
        });
    },
    closeRole() {
      this.$bvModal.hide("modal-confirm-role");
    },
    closeStatus() {
      this.$bvModal.hide("modal-confirm-status");
    },
    closeEmail() {
      this.$bvModal.hide("modal-confirm-email");
    },
  },
});
