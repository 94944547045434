





























































































































import Vue from "vue";
import ReportTable from "@/components/adminComponents/ReportTable.vue";
import PageHeading from "@/components/PageHeading.vue";
import { mapGetters } from "vuex";
import AuthService from "@/services/AuthService";
import { Log } from "@/types";
import ModalContainer from "@/components/ModalContainer.vue";

export default Vue.extend({
  name: "SingleUserReport",
  components: { PageHeading, ReportTable, ModalContainer },
  data: () => ({
    user: {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      filled: false,
    },
    device: "",
    remote: "",
  }),
  mounted() {
    this.$store.dispatch("logs/fetchSingleUserLogs", this.$route.params.id);
    this.$store.dispatch("logs/fetchReportUserLoan", this.$route.params.id);
  },
  beforeMount() {
    AuthService.getContactDeatilsFromAC(this.$route.params.id).then((r) => {
      this.user = r.data;
      this.user.filled = true;
    });
  },
  computed: {
    ...mapGetters({
      logs: "logs/getLogs",
      report: "logs/getReport",
    }),
  },
  methods: {
    informationModal(data: Log) {
      this.device = data.device;
      this.remote = data.remote_address;
      this.$bvModal.show("modal-log-information");
    },
  },
});
