






















































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import { apiAxios } from "@/store/apiAxios";

export default Vue.extend({
  components: {
    PageHeading,
  },
  mounted() {
    this.$store.dispatch("user/popupAlert");
  },
  computed: {
    popupAlert: {
      get(): boolean {
        return this.$store.getters["user/getPopupAlert"];
      },
      set(value: boolean) {
        this.$store.commit("user/setPopupAlert", value);
      },
    },
    titleBoxCustom: {
      get(): string {
        return this.$store.getters["user/getTitleBoxCustom"];
      },
      set(value: string) {
        this.$store.commit("user/setTitleBoxCustom", value);
      },
    },
    descriptionBoxCustom: {
      get(): string {
        return this.$store.getters["user/getDescriptionBoxCustom"];
      },
      set(value: string) {
        this.$store.commit("user/setDescriptionBoxCustom", value);
      },
    },
  },
  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("title", this.titleBoxCustom);
      formData.append("description", this.descriptionBoxCustom);
      formData.append("active", this.popupAlert ? "1" : "0");

      apiAxios.post("api/admin/update-popup", formData).then(() => {
        window.localStorage.setItem("popupViewed", "false");
        this.$store.commit("setViewedPopup", false);
        location.reload();
      });
    },
  },
});
